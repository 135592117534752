/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Row,
  theme,
  Typography,
} from "antd";

import { Route, Routes, useNavigate } from "react-router-dom";
import Logo from "./assets/images/logo.png";
import "./App.css";
import useAuth from "./hooks/useAuth";
import { useAppContext } from "./context/AppContext";
import { USER_API } from "./api/api";
import {
  HomeOutlined,
  ContainerOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import Dashboard from "./pages/Dashboard";
import Containers from "./pages/Containers";
import Profile from "./pages/Profile";
import ContainerDetails from "./pages/ContainerDetails";
import ExaminedImages from "./pages/ExaminedImages";

const { Content, Footer } = Layout;
const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { user, login, loading, setUser } = useAuth();
  const { setUserPermissions } = useAppContext();
  const navigate = useNavigate();

  const handleLogin = (values) => {
    login(values.username, values.password);
  };

  useEffect(() => {
    if (user) {
      USER_API.getUser(user.id).then((response) => {
        if (response.data) {
          const userPermissions = response?.data?.role?.permissions.map(
            (permission) => permission.name
          );
          setUser(response.data);
          setUserPermissions(userPermissions);
        } else {
          setUser(null);
          navigate("/");
        }
      });
    }
  }, []);

  return user ? (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ margin: "5px 16px 20px" }}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/containers" element={<Containers />} />
          <Route path="/containers/:id" element={<ContainerDetails />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/examined-images" element={<ExaminedImages />} />
        </Routes>
      </Content>
      <Footer
        style={{
          padding: "15px 15px",
          background: "#ffffff",
          position: "sticky",
          bottom: "0",
        }}
      >
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <Button
            type="text"
            size="large"
            onClick={() => navigate("/")}
            icon={<HomeOutlined style={{ fontSize: 25 }} />}
          />
          <Button
            type="text"
            size="large"
            onClick={() => navigate("/containers")}
            icon={<ContainerOutlined style={{ fontSize: 25 }} />}
          />
          <Button
            type="text"
            size="large"
            onClick={() => navigate("/examined-images")}
            icon={<PictureOutlined style={{ fontSize: 25 }} />}
          />
          <Avatar
            onClick={() => navigate("/profile")}
            style={{
              backgroundColor: "#00a2ae",
              cursor: "pointer",
            }}
            size={35}
          >
            {user?.name.split("")[0]}
          </Avatar>
        </Flex>
      </Footer>
    </Layout>
  ) : (
    <Layout style={{ minHeight: "100vh", background: "#74b9ff" }}>
      <Content style={{ margin: "100px 16px 0" }}>
        <Row justify="center" align="middle" gutter={[32, 32]}>
          <Col xs={24} style={{ textAlign: "center" }}>
            <Image src={Logo} preview={false} width={120} />
          </Col>
          <Col xs={24}>
            <Card
              title="Login"
              headStyle={{ border: 0 }}
              bordered={false}
              hoverable
            >
              <Form onFinish={handleLogin} layout="vertical">
                <Form.Item
                  label="Username / SSO ID"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Email , username, sso id or mobile"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        JNPA ©2024. Developed and Maintained by{" "}
        <Typography.Link href="https://www.iwebs.co.in" target="_blank">
          iWebs
        </Typography.Link>{" "}
      </Footer>
    </Layout>
  );
};

export default App;

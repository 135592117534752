import axios from "./axios";

const AUTH_API = {
  login: (data) =>
    axios({
      url: `/auth/login`,
      method: "POST",
      data,
    }),
};

const CORE_API = {
  getCoreData: (type) =>
    axios({
      url: `/core/${type}`,
      method: "GET",
    }),

  getRoles: (type) =>
    axios({
      url: `/core/roles`,
      method: "GET",
    }),
  createRole: (data) =>
    axios({
      url: `/core/roles`,
      method: "POST",
      data,
    }),

  updateRole: (data, id) =>
    axios({
      url: `/core/roles/${id}`,
      method: "PATCH",
      data,
    }),
  getStats: () =>
    axios({
      url: `/core/dashboard/stats`,
      method: "GET",
    }),
};

const USER_API = {
  getUsers: (params) =>
    axios({
      url: `/users`,
      method: "GET",
      params,
    }),

  getUser: (id) =>
    axios({
      url: `/users/${id}`,
      method: "GET",
    }),

  createUser: (data) =>
    axios({
      url: `/users`,
      method: "POST",
      data,
    }),

  updateUser: (status, id) =>
    axios({
      url: `/users/${id}`,
      method: "PATCH",
      data: { status: status },
    }),

  resetPassword: (id) =>
    axios({
      url: `/users/${id}/reset-password`,
      method: "PATCH",
    }),
};

const CASE_API = {
  getCases: (params) =>
    axios({
      url: `/cases`,
      method: "GET",
      params,
    }),
};

const CONTAINER_API = {
  getContainers: (params) =>
    axios({
      url: `/containers/search-for-examined`,
      method: "GET",
      params,
    }),

  getExaminedImage: (params) =>
    axios({
      url: `/containers/examined-images`,
      method: "GET",
      params,
    }),
  createExaminedImage: (data) =>
    axios({
      url: `/containers/examined-images`,
      method: "POST",
      data,
    }),
};

export { AUTH_API, CORE_API, USER_API, CASE_API, CONTAINER_API };

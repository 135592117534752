import { Typography, Row, Col, Card, Statistic } from "antd";
import { useState, useEffect } from "react";
import { CORE_API } from "../api/api";

const { Title } = Typography;

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getStats = () => {
    CORE_API.getStats().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getStats();
  }, []);
  return (
    <div>
      <Title level={4}>Dashboard</Title>
      <Row gutter={[32, 32]} style={{ marginTop: 30 }}>
        <Col xs={24}>
          <Card bordered={false} loading={loading}>
            <Statistic
              title="Total Containers"
              value={data?.total_containers}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false} loading={loading}>
            <Statistic
              title="Scanned Containers"
              value={data?.total_scanned}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false} loading={loading}>
            <Statistic
              title="Not Scanned Containers"
              value={data?.total_not_scanned}
              valueStyle={{ color: "red" }}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false} loading={loading}>
            <Statistic
              title="Examined Containers"
              value={data?.total_examined}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

import {
  Typography,
  Descriptions,
  Card,
  Image,
  Drawer,
  Row,
  Col,
  Button,
  Input,
  Form,
  DatePicker,
  Select,
  Upload,
  message,
} from "antd";
import { LeftOutlined, UploadOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CONTAINER_API, CORE_API } from "../api/api";
import dayjs from "dayjs";
import { jsonToFormData } from "./ExaminedImages";
import sendNotification from "../service/notification";
import handleVibrate from "../service/vibrate";

const ContainerDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [discrepancyFound, setDiscrepancyFound] = useState("N");
  const [submitting, setSubmitting] = useState(false);
  const [chapterHeadings, setChapterHeadings] = useState([]);
  const { container } = location.state;

  const imageprops = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: async (info) => {
      setFileList(info.fileList);
    },
    fileList,
  };

  const getChapterHeadings = () => {
    CORE_API.getCoreData("chapter-headings").then((res) => {
      setChapterHeadings(res.data);
    });
  };
  useEffect(() => {
    getChapterHeadings();
  }, []);

  const save = () => {
    if (fileList.length < 1) {
      sendNotification("Please upload atleast 1 image", "Error");
      handleVibrate();
    }
    setSubmitting(true);
    const data = form.getFieldsValue();
    data.container_number = container?.container_number?._id;
    data.date_of_examination = data.date_of_examination
      ? `${dayjs(data.date_of_examination).format("YYYY-M-D")}`
      : undefined;
    data.bill_date = data.bill_date
      ? `${dayjs(data.bill_date).format("YYYY-M-D")}`
      : undefined;

    const formData = jsonToFormData(data);
    fileList.forEach((file) => {
      formData.append("examined_images", file.originFileObj);
    });

    CONTAINER_API.createExaminedImage(formData).then((res) => {
      message.success("Examined images saved successfully!");
      setSubmitting(false);
      setOpen(false);
      form.resetFields();
      sendNotification("Examined Images saved successfully!", "Success");
      handleVibrate();
      navigate(`/containers/`);
    });
  };

  console.log(container);

  return (
    <div>
      <Typography.Title level={4}>
        <Typography.Link onClick={() => window.history.go(-1)}>
          <LeftOutlined style={{ fontSize: 18 }} />
        </Typography.Link>{" "}
        #{container?.container_number?.container_number}
      </Typography.Title>

      <Card
        style={{ marginTop: 30 }}
        title="Container Details"
        cover={
          container?.images.length > 0 ? (
            <Image
              src={container?.images[0]}
              height={200}
              style={{ border: "2px solid blue" }}
              title="Container Image"
            />
          ) : null
        }
        extra={
          container &&
          container?.examined_images.length === 0 && (
            <Button type="default" onClick={() => setOpen(true)}>
              Add Examined Images
            </Button>
          )
        }
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Container Number">
            {container?.container_number?.container_number}
          </Descriptions.Item>
          <Descriptions.Item label="IGM Number">
            {container?.container_number?.igm_number}
          </Descriptions.Item>
          <Descriptions.Item label="IGM Date">
            {container?.container_number?.igm_date}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      {container && container?.examined_images.length > 0 && (
        <Card style={{ marginTop: 30 }} title="Examined Images">
          <Row gutter={[16, 16]}>
            {container?.examined_images?.map((row) =>
              row.images.map((image) => (
                <Col xs={12}>
                  <Image
                    src={image}
                    width={"100%"}
                    height={100}
                    style={{ border: "2px solid red" }}
                  />
                </Col>
              ))
            )}
          </Row>
        </Card>
      )}

      <Drawer
        title="Upload examined images"
        destroyOnClose={true}
        maskClosable={false}
        width={900}
        open={open}
        onClose={() => setOpen(false)}
        footer={
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={submitting}
            onClick={() => save()}
          >
            Submit
          </Button>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            initialValue={container?.container_number?.container_number}
            name="container_number"
            label="Search Container by IGM  No or container number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item label="Chapter heading" name="chapter_headings">
            <Select
              allowClear
              placeholder="Search by chapter heading"
              showSearch
              optionFilterProp="children"
              filterSort={(optionA, optionB) => {
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              style={{ width: "100%" }}
            >
              {chapterHeadings.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="bill_no"
                label="Bill Number"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="bill_date" label="Bill date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="date_of_examination" label="Examination date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label="IGM Description"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="examination_findings"
            label="Examination findings"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="discrepancy_found"
            label=" Is Discrepancy found"
            rules={[{ required: true }]}
            extra="This will automatically create a case for the examination finding for this container"
          >
            <Select
              placeholder="Is discrepancy found"
              value={discrepancyFound}
              onChange={(value) => {
                console.log("value", value);
                setDiscrepancyFound(value);
              }}
            >
              <Select.Option value="N"> No</Select.Option>
              <Select.Option value="Y"> Yes</Select.Option>
            </Select>
          </Form.Item>
          {discrepancyFound === "Y" && (
            <>
              <Form.Item name="importer_name" label="Importer name">
                <Input />
              </Form.Item>
              <Form.Item name="additional_revenue" label="Additional_Revenue">
                <Input />
              </Form.Item>
              <Form.Item
                name="scanning_result"
                label=" Scanning result"
                rules={[{ required: true }]}
              >
                <Select placeholder="scanning result">
                  <Select.Option value={"clean"}> Clean</Select.Option>
                  <Select.Option value={"mismatch"}> Mismatch</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Select examined images"
            rules={[{ required: true }]}
            required
            help="Please select at least 4 image"
          >
            <Upload
              {...imageprops}
              accept="image/*"
              listType="picture"
              multiple
            >
              <Button block icon={<UploadOutlined />}>
                Select Files
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};
export default ContainerDetails;

import axios from "axios";
import { notification } from "antd";
import Cookies from "js-cookie";
// Next we make an 'instance' of it
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function handleSuccess(response) {
  return { data: response.data };
}

function handleError(error) {
  if (error.message === "Network Error") {
    return Promise.reject(error);
  }
  const { status, data } = error.response;
  switch (status) {
    case 400:
      break;
    case 403:
      break;
    case 401:
      break;
    case 404:
      break;
    case 500:
      break;
    case 502:
      break;
    default:
      break;
  }

  if (status === 401 && data.status !== "Password Not found") {
    Cookies.remove("authToken");
    Cookies.remove("user");
    window.location.reload();
  }

  const errorMessage = error.response.data.message;
  if (Array.isArray(errorMessage)) {
    errorMessage.map((err) =>
      notification.error({
        message: "Error",
        description: err,
        duration: 5,
      })
    );
  } else {
    notification.error({
      message: "Error",
      description: errorMessage,
      duration: 5,
    });
  }

  return false;
}

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;

import { createContext, useState, useContext } from "react";

export const AppContext = createContext({
  permissions: [],
  setPermissions: () => {},
  userPermissions: [],
  setUserPermissions: () => {},
});

export const useAppContext = () => useContext(AppContext);

export const ConfigurationProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  return (
    <AppContext.Provider
      value={{
        permissions,
        setPermissions,
        userPermissions,
        setUserPermissions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

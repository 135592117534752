import { Button, Descriptions, Typography } from "antd";
import useAuth from "../hooks/useAuth";
import { LogoutOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Profile = () => {
  const { user, logout } = useAuth();
  return (
    <div>
      <Title level={4}>{user.name}</Title>
      <Descriptions layout="horizontal" column={1} style={{ marginTop: 50 }}>
        <Descriptions.Item label="SSO ID">
          {user?.employee_id}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
        <Descriptions.Item label="Mobile">{user?.mobile}</Descriptions.Item>
        <Descriptions.Item label="Role">{user?.role?.name}</Descriptions.Item>
        <Descriptions.Item label="Designation">
          {user?.designation}
        </Descriptions.Item>
        <Descriptions.Item label="Port">{user?.port?.name}</Descriptions.Item>
      </Descriptions>
      <Button
        style={{ marginTop: 50 }}
        onClick={logout}
        type="default"
        size="large"
        ghost
        danger
        block
        icon={<LogoutOutlined />}
      >
        Logout
      </Button>

      <Typography.Paragraph style={{ marginTop: 50 }}>
        For any technical support Contact us on +91-9884094592 or Mail us at
        info@iwebs.co.in.
      </Typography.Paragraph>
    </div>
  );
};

export default Profile;

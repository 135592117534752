import {
  Typography,
  Row,
  Col,
  Card,
  Skeleton,
  Descriptions,
  Empty,
  Tag,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import { CONTAINER_API } from "../api/api";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Containers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = () => {
    setLoading(true);
    CONTAINER_API.getContainers({}).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Title level={4}>Containers</Title>
      {loading && <Skeleton paragraph={{ rows: 10 }}></Skeleton>}
      {!loading && data?.length === 0 && (
        <Empty
          description={<Typography.Text>No Containers Found</Typography.Text>}
        />
      )}

      <Row gutter={[32, 32]} style={{ marginTop: 30 }}>
        {data?.map((container) => (
          <Col key={container.id} xs={24}>
            <Card
              headStyle={{ border: 0 }}
              bordered={false}
              title={container?.container_number?.container_number}
              cover={
                container?.images.length > 0 ? (
                  <Image
                    src={container?.images[0]}
                    height={200}
                    style={{ border: "2px solid blue" }}
                    title="Container Image"
                  />
                ) : null
              }
              extra={<DoubleRightOutlined />}
              hoverable
              onClick={() =>
                navigate(`/containers/${container?.container_number?._id}`, {
                  state: { container },
                })
              }
            >
              <Descriptions column={1}>
                <Descriptions.Item label="IGM Number">
                  {container?.container_number?.igm_number}
                </Descriptions.Item>
                <Descriptions.Item label="IGM Date">
                  {container?.container_number?.igm_date}
                </Descriptions.Item>

                <Descriptions.Item label="Reason of not scanning">
                  {container?.reason}
                </Descriptions.Item>
                <Descriptions.Item label="Examined Image">
                  {container?.examined_images.length > 0 ? (
                    <Tag color="green">uploaded</Tag>
                  ) : (
                    <Tag color="red">Not uploaded</Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Containers;

import {
  Typography,
  Row,
  Image,
  Card,
  Col,
  Descriptions,
  Pagination,
  Skeleton,
  Empty,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { CONTAINER_API } from "../api/api";

export function formatDate(date) {
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 101).toString().substring(1);
  var day = (date.getDate() + 100).toString().substring(1);
  return year + "-" + month + "-" + day;
}
export const jsonToFormData = (jsonObject) => {
  const formData = new FormData();
  function appendFormData(data, parentKey) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        // Do not include index in the key
        const key = parentKey ? `${parentKey}[]` : "[]";
        appendFormData(item, key);
      });
    } else if (
      typeof data === "object" &&
      data !== null &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        if (parentKey) {
          appendFormData(data[key], `${parentKey}[${key}]`);
        } else {
          appendFormData(data[key], key);
        }
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  // Append JSON data to FormData
  appendFormData(jsonObject);

  return formData;
};

const ExaminedImages = (props) => {
  const [examinedImage, setExaminedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [params, setParams] = useState({});

  const getExaminedImage = useCallback(() => {
    setLoading(true);
    const newParams = Object.assign(params, {
      container_number: props.container_id,
    });
    CONTAINER_API.getExaminedImage(newParams)
      .then((response) => {
        setExaminedImage(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    getExaminedImage();
  }, [getExaminedImage, params]);
  return (
    <div>
      <Typography.Title level={4}>Examined Images</Typography.Title>
      {examinedImage && examinedImage?.results.length === 0 && (
        <Empty
          description={
            <Typography.Text>No Examined Images Found</Typography.Text>
          }
        />
      )}
      <Row
        style={{ marginTop: 30 }}
        gutter={[32, 32]}
        justify="center"
        align="bottom"
      >
        {loading && <Skeleton paragraph={{ rows: 10 }} />}
        {examinedImage &&
          examinedImage?.results.map((row) => (
            <Col xs={24}>
              <Card
                title={row?.container_number?.container_number}
                cover={
                  <Image.PreviewGroup items={row.images}>
                    <Image alt="example" src={row.images[0]} height={300} />
                  </Image.PreviewGroup>
                }
              >
                <Descriptions column={2}>
                  <Descriptions.Item label="IGM Date">
                    {formatDate(new Date(row?.container_number?.igm_date))}
                  </Descriptions.Item>
                  <Descriptions.Item label="IGM Number">
                    {row?.container_number?.igm_number}
                  </Descriptions.Item>

                  <Descriptions.Item label="Bill No">
                    {formatDate(new Date(row?.bill_date))}
                  </Descriptions.Item>
                  <Descriptions.Item label="IGM Number">
                    {row?.bill_no}
                  </Descriptions.Item>
                  <Descriptions.Item label="Chapter heading">
                    {row?.chapter_headings?.name}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          ))}
        {examinedImage?.results.length > 0 && (
          <Pagination
            align="center"
            current={examinedImage?.page}
            total={examinedImage?.totalResults}
            pageSize={examinedImage?.limit}
            onChange={(value) => setParams({ ...params, page: value })}
          />
        )}
      </Row>
    </div>
  );
};
export default ExaminedImages;
